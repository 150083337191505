.move-to-top-container {
  position: fixed;
  bottom: 20px;
  right: 50px;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.7;
  background-color: #5db0c6;

  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  .move-to-top-icon {
    font-size: 1.4rem;
    color: #fff;
  }
}
