.app__social {
  display: flex;
  gap: 1rem;

  .social-icon {
    font-size: 2rem; // Adjust the size as needed
    transition: background-color 0.3s;
    cursor: pointer;
    padding: 0.5rem; // Add some padding for better hover effect
    border-radius: 50%; // Make it circular
    outline: none; // Remove outline

    &:hover {
      outline: none; // Ensure no outline on hover
    }

    &.twitter:hover {
      background-color: #000000; // Twitter blue
      color: white; // Change icon color to white for better visibility
    }

    &.facebook:hover {
      background-color: #3b5998; // Facebook blue
      color: white; // Change icon color to white for better visibility
    }

    &.github:hover {
      background-color: #333; // GitHub dark
      color: white; // Change icon color to white for better visibility
    }

    &.instagram:hover {
      background: linear-gradient(
        45deg,
        #f9ce34,
        #ee2a7b,
        #6228d7
      ); // Instagram gradient
      color: white; // Change icon color to white for better visibility
    }

    &.linkedin:hover {
      background-color: #2161d7; // Instagram red
      color: white; // Change icon color to white for better visibility
    }
  }
}
